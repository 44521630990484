<template>
  <div class="setting-callendar-th style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="4">
          <div class="title-header">
            Calendrier TH
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="getLoadingCalenderTh"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="3">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="getErrorCalenderTh">
              <ul v-if="Array.isArray(getErrorCalenderTh)" class="mb-0">
                <li v-for="(e, index) in getErrorCalenderTh" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getErrorCalenderTh }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="addCalenderModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter une année</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addCalenderModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="form" lazy-validation>
            <v-select
              label="Année"
              placeholder="Séléctionnez"
              :items="ListYear"
              :persistent-placeholder="true"
              v-model="calenderAdd"
              required
              :rules="[v => !!v || 'Année est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              :menu-props="{ bottom: true, offsetY: true }"
              no-data-text="Aucune année"
              outlined
              dense
              color="#704ad1"
              item-color="#704ad1"
            >
            </v-select>
          </v-form>
        </v-card-text>
        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="handleSubmitCalender"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addCalenderModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting pt-1 pb-1">
        <div class="right-block-filter">
          <v-select
            label="Année"
            placeholder="Séléctionnez"
            :items="ListYear"
            :persistent-placeholder="true"
            v-model="filteryear"
            @change="handleChangeYear"
            :menu-props="{ bottom: true, offsetY: true }"
            no-data-text="Aucune année"
            outlined
            dense
            color="#704ad1"
            item-color="#704ad1"
          >
          </v-select>
        </div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('CTTHAA')"
                @click="openModalAjouterYear"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span> Ajouter une année</span>
          </v-tooltip>
        </div>
      </div>
    </div>

    <div class="calender-body">
      <div class="calender-card">
        <div
          v-for="(item, indexMonth) in getCalendersTh"
          :key="'mounths' + item.id"
          class="card-mounts"
        >
          <div>
            <div class="card-mounths-header ">
              {{ item.month_name | FormateMonthName }}
            </div>
            <div class="card-mountes-body">
              <div
                class="card-mounths-item"
                v-for="(week, index) in item.weeks"
                :key="index + week"
              >
                <div v-if="checkPermission('CTTHDLS')">
                  <font-awesome-icon
                    v-if="index == 0 && item.month != 1"
                    icon="angle-left"
                    class="icon-flesh"
                    title="Déplacer la semaine"
                    @click="
                      handleDragWeek({
                        type: 'backward',
                        week: week,
                        month: item,
                        month_to: getCalendersTh[indexMonth - 1].id,
                        year: getCalendersTh.year
                      })
                    "
                  />
                </div>

                <div>{{ week.num | FormatWeek }}</div>
                <div class="conteur-depot-in-week">
                  {{ week.depots.length }}
                </div>
                <div class="box-rigth-details">
                  <div v-if="checkPermission('CTTHV')">
                    <font-awesome-icon
                      icon="eye"
                      class="icon-eye"
                      @click="handleShowSupportFiliale(item, week, index)"
                    />
                  </div>
                  <div
                    v-if="checkPermission('CTTHDLS')"
                    :class="{
                      'ml-2':
                        index == item.weeks.length - 1 && item.month != 12,
                      'ml-3': !(
                        index == item.weeks.length - 1 && item.month != 12
                      )
                    }"
                  >
                    <font-awesome-icon
                      v-if="index == item.weeks.length - 1 && item.month != 12"
                      icon="angle-right"
                      class="icon-flesh"
                      title="Déplacer la semaine"
                      @click="
                        handleDragWeek({
                          type: 'forward',
                          week: week,
                          month: item,
                          month_to: getCalendersTh[indexMonth + 1].id,
                          year: getCalendersTh.year
                        })
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- show depot week modal  -->
    <v-dialog
      v-model="addDepotWeekModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label
            class="label-header"
            v-if="
              monthToUpdate && monthToUpdate.weeks && monthToUpdate.weeks.length
            "
            >{{ 'Semaine ' + monthToUpdate.weeks[indexWeek].num }}</v-label
          >
          <div
            v-if="getLoadingCalenderWeekTh"
            class=" chargement-message-calender text-aligne-center mt-2"
          >
            Chargement en cours ...
          </div>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addDepotWeekModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            class="form-modal-custom-style"
            v-if="
              monthToUpdate &&
                monthToUpdate.weeks &&
                monthToUpdate.weeks[indexWeek] &&
                monthToUpdate.weeks[indexWeek].depots
            "
          >
            <div class="card-mounts">
              <!-- <div class="card-mounths-header-2">
                {{ 'Semaine ' + monthToUpdate.weeks[indexWeek].num }}
              </div> -->

              <div
                class="box-depot-week-item"
                v-for="itemF in monthToUpdate.weeks[indexWeek].filiales"
                :key="itemF.id"
              >
                <div class="groupe-filiale-box">
                  <div class="card-mounths-header text-left">
                    {{ itemF.name }}
                  </div>
                  <div
                    class="card-mounths-item-modal"
                    v-for="itemD in itemF.depots"
                    :key="itemD.id"
                  >
                    <v-autocomplete
                      placeholder="Séléctionnez"
                      :items="computedGetSettingFilialesTh"
                      :persistent-placeholder="true"
                      v-model="computedDepotName(itemD).filiale"
                      @change="updateFilialeDepot($event, itemD)"
                      :menu-props="{ bottom: true, offsetY: true }"
                      outlined
                      dense
                      no-data-text="Aucune option trouvée"
                      color="#704ad1"
                      item-color="#704ad1"
                      return-object
                      item-text="name"
                      item-value="name"
                    >
                    </v-autocomplete>
                    <div class="ml-2 mr-2">
                      - {{ computedDepotName(itemD).year }} -
                    </div>
                    <v-text-field
                      :value="computedDepotName(itemD).name"
                      @change="updateNameDepot($event, itemD)"
                      :persistent-placeholder="true"
                      outlined
                      placeholder="Nom de dépôt"
                      color="#704ad1"
                      class="mr-2"
                    >
                    </v-text-field>
                    <div v-if="checkPermission('CTTHSLD')">
                      <font-awesome-icon
                        icon="times"
                        class="icon-closed"
                        title="Supprimer la dépôt"
                        @click="DeleteDepotInMonth(itemD, itemF)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="depot-card-modal card-mounths-item-modal mt-4"
                v-if="weekToUpdate != null"
              >
                <v-autocomplete
                  class="input-depot-card-modal"
                  placeholder="Séléctionnez"
                  :items="computedGetSettingFilialesTh"
                  :persistent-placeholder="true"
                  v-model="filialeToAdd"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  dense
                  color="#704ad1"
                  item-color="#704ad1"
                  return-object
                  item-text="name"
                  item-value="name"
                  no-data-text="Aucune option trouvée"
                >
                </v-autocomplete>
                <div class="ml-2 mr-2">- {{ filteryear }} -</div>

                <v-text-field
                  class="input-depot-card-modal"
                  v-model="depotToAdd"
                  required
                  :persistent-placeholder="true"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </div>
            </div>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="error" class="error-msg">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            v-if="checkPermission('CTTHAND')"
            color="#704ad1"
            outlined
            @click="
              weekToUpdate != null
                ? handleSubmitDepot()
                : handleShowAddDepotModal(weekDepotToUpdate)
            "
          >
            <font-awesome-icon icon="plus" class="mr-2" />
            {{ weekToUpdate == null ? 'Numéro de dépôt' : 'Ajouter la dépôt' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Calendrier-TH',
  data() {
    return {
      addCalenderModal: false,
      addDepotWeekModal: false,
      calenderAdd: null,
      ListYear: [],
      filteryear: null,
      error: null,
      loading: false,
      depotToAdd: null,
      filialeToAdd: null,
      weekToUpdate: null,
      weekDepotToUpdate: null,
      monthToUpdate: null,
      indexWeek: null
    };
  },
  methods: {
    ...mapActions([
      'AddCalendrierTh',
      'getCallenderTh',
      'addDepotToMonth',
      'deleteDepotInMonth',
      'updateNameDepotTh',
      'getSettingFilialeTh',
      'updateFilialeDepotTh',
      'DragWeekTh'
    ]),
    openModalAjouterYear() {
      this.addCalenderModal = true;
    },
    resetModal() {
      this.calenderAdd = null;
      this.error = null;
      this.loading = false;
      this.depotToAdd = null;
      this.filialeToAdd = null;
      this.weekToUpdate = null;
      this.weekDepotToUpdate = null;
      this.monthToUpdate = null;
    },
    hideModal(ref) {
      this[ref] = false;
      if (ref == 'addCalenderModal') {
        this.$refs.form.reset();
      }
      this.resetModal();
    },
    async handleSubmitCalender() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const response = await this.AddCalendrierTh(this.calenderAdd);
        if (response.success) {
          this.filteryear = this.calenderAdd;
          this.error = null;
          this.loading = false;
          this.hideModal('addCalenderModal');
        } else {
          this.error = response.error;
          this.loading = false;
        }
      }
    },
    handleChangeYear() {
      this.getCallenderTh(this.filteryear);
    },
    handleShowAddDepotModal(item) {
      this.weekToUpdate = item;
    },
    async handleSubmitDepot() {
      this.error = null;
      const response = await this.addDepotToMonth({
        Week: this.weekToUpdate,
        depot:
          this.depotToAdd.length == 1 ? '0' + this.depotToAdd : this.depotToAdd,
        filiale: this.filialeToAdd,
        month: this.monthToUpdate
      });
      if (response.succes == true) {
        this.weekToUpdate = null;
        this.depotToAdd = null;
        this.filialeToAdd = null;
        this.error = null;
      } else {
        this.error = response.error;
      }
    },
    async DeleteDepotInMonth(itemD, itemF) {
      this.error = null;
      const response = await this.deleteDepotInMonth({
        month: this.monthToUpdate,
        week: this.weekDepotToUpdate,
        depot: itemD,
        filiale: itemF
      });
      if (response.succes == true) {
        this.error = null;
      } else {
        this.error = response.error;
      }
    },
    async updateNameDepot(e, depot) {
      this.error = null;
      const response = await this.updateNameDepotTh({
        name:
          e.target.value.length == 1 ? '0' + e.target.value : e.target.value,
        month: this.monthToUpdate,
        depot: depot,
        filiale: this.computedDepotName(depot).filiale
      });
      if (response.succes == true) {
        this.error = null;
      } else {
        this.error = response.error;
      }
    },
    async updateFilialeDepot(e, depot) {
      this.error = null;
      const response = await this.updateFilialeDepotTh({
        month: this.monthToUpdate,
        depot: depot,
        depot_name: this.computedDepotName(depot).name,
        filiale: e
      });
      if (response.succes == true) {
        this.error = null;
      } else {
        this.error = response.error;
      }
    },
    handleShowSupportFiliale(month, week, index) {
      this.monthToUpdate = month;
      this.weekDepotToUpdate = week;
      this.indexWeek = index;
      this.addDepotWeekModal = true;
    },
    handleDragWeek(paayload) {
      this.DragWeekTh(paayload);
    }
  },
  computed: {
    ...mapGetters([
      'getLoadingCalenderTh',
      'getCalendersTh',
      'getErrorCalenderTh',
      'getSettingFilialesTh',
      'getLoadingCalenderWeekTh',
      'checkPermission'
    ]),
    computedGetSettingFilialesTh: function() {
      return this.getSettingFilialesTh.filter(item => item.name != null);
    },
    computedDepotName() {
      return function(name) {
        return {
          filiale: name.filiale_name,
          year: name.year,
          name: name.depot_number
        };
      };
    }
  },
  components: {},
  filters: {
    FormateMonthName: value => {
      return value[0].toUpperCase() + value.slice(1);
    },
    FormatWeek: value => {
      switch (value) {
        case value:
          return 'Semaine ' + value;
        default:
          return value;
      }
    }
  },
  mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    this.getCallenderTh(this.filteryear);
    this.getSettingFilialeTh();
  }
};
</script>

<style scoped lang="scss">
.setting-callendar-th {
  width: 100%;

  .calender-body {
    margin-top: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 205px);
    .calender-card {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 7px;
      justify-content: center;
    }
  }
  .card-mounts {
    // border: 1px solid rgba(108, 117, 125, 0.30196078431372547);
    margin-right: 8px;
    background-color: #f1edfb;
    // border-radius: 4px;
    display: table-cell;
    vertical-align: top;
    padding: 2px;
    margin: 5px;
    width: 181px;
    .card-mounths-header,
    .card-mounths-header-2 {
      position: relative;
      width: 100%;
      padding: 0px;
      font-weight: 600;
      font-size: 12px;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
    }
    .card-mountes-body {
      .card-mounths-item {
        position: relative;
        overflow: visible;
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        // border: 1px solid rgba(108, 117, 125, 0.3215686274509804);
        padding: 4px;
        background-color: #fff;
        width: 100%;
        // border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        .icon-flesh {
          cursor: pointer;
          font-size: 10px;
          margin-right: 2px;
          color: #704ad1;
          &:hover {
            font-size: 13px;
          }
        }
        .icon-closed {
          cursor: pointer;
          font-size: 13px;
          margin-right: 2px;
          color: #704ad1;
        }
        .icon-eye {
          cursor: pointer;
          font-size: 13px;
          margin-right: 2px;
          color: #704ad1;
        }
        .conteur-depot-in-week {
          background-color: #8361d7;
          padding: 4px;
          min-width: 18px;
          color: white;
          text-align: center;
          height: 18px;
          border-radius: 14px;
          text-align: center;
          font-size: 9px;
        }
        .input-focus-depot {
          width: 25%;
          background-color: transparent;
          border: none;
          color: #000;
          overflow: visible;
          border: 0;
          font-size: 13px;
          text-overflow: ellipsis;
          outline: unset;
          &:focus {
            background-color: #ededf4;
          }
        }
        .b-form-select-new-style {
          border-radius: 5px;
          width: 110px;
          background-color: #fcfcfc;
          padding-left: 2px;

          padding-top: 0px;

          padding-bottom: 0px;

          height: 20px;
        }
        .box-rigth-details {
          display: flex;
          justify-content: space-between;
        }
      }
      .button-add-depot {
        margin: auto;
        margin-top: 5px;
        text-align: center;
      }
    }
  }
  .box-all-filter-calendre-th {
    height: 31px;
    width: 100%;
    background-color: transparent;
    border-radius: 41px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }
}
</style>
<style lang="scss">
.depot-card-modal {
  background-color: #f1edfb !important;
  .input-focus-depot {
    height: 21px;
  }
  .input-depot-card-modal {
    background-color: #fff !important;
  }
}
.button-add-depot {
  margin: auto;
  margin-top: 5px;
  text-align: center;
}
.card-mounts {
  color: black;
  // border: 1px solid rgb(112 74 209);
  margin-right: 8px;
  background-color: #fff;
  // border-radius: 4px;
  vertical-align: top;
  padding: 2px;
  margin: 5px;
  .card-mounths-header,
  .card-mounths-header-2 {
    position: relative;
    width: 100%;
    padding: 0px;
    font-weight: 600;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
  .card-mounths-header {
    color: #704ad1;
  }
  .card-mounths-header-2 {
    color: #704ad1;
  }
}
.card-mounths-item-modal {
  color: #000;
  position: relative;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  // border: 1px solid rgba(108, 117, 125, 0.3215686274509804);
  padding: 4px;
  background-color: transparent;
  width: 100%;
  // border-radius: 4px;
  font-size: 11px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  .v-input__slot {
    margin-bottom: 0px !important;
  }
  .icon-flesh {
    cursor: pointer;
    font-size: 10px;
    margin-right: 2px;
    color: #704ad1;
    &:hover {
      font-size: 13px;
    }
  }
  .icon-closed {
    cursor: pointer;
    font-size: 13px;
    margin-right: 2px;
    color: #704ad1;
  }
  .input-focus-depot {
    width: 40%;
    background-color: #fcfcfc;
    border: none;
    color: #000;
    overflow: visible;
    border: 0;
    border-radius: 5px;
    font-size: 13px;
    text-overflow: ellipsis;
    outline: unset;
    text-align: center;
    &:focus {
      background-color: #fcfcfc;
    }
  }
  .b-form-select-new-style {
    border-radius: 5px;
    width: 40%;
    background-color: #fcfcfc;
    padding-left: 2px;

    padding-top: 0px;

    padding-bottom: 0px;

    height: 20px;
  }
  .box-rigth-details {
    display: flex;
    justify-content: space-between;
  }
}
.groupe-filiale-box {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  background-color: #f1edfb;
  // border-radius: 5px;
}
.box-depot-week-item {
  max-height: 320px;
  overflow-y: auto;
}
.box-depot-week-item::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.box-depot-week-item::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.box-depot-week-item::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 7px;
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.ligne-box-setting-header {
  margin-bottom: 17px;
  width: 100% !important;
  margin-top: 9px;
  border-bottom: 1px solid #cccccc73;
}
</style>
